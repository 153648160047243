import { graphql, Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import BrandColorsHorizontalDivider from "../components/BrandColorsHorizontalDivider";
import GraphQLErrorList from "../components/graphql-error-list";
import PortableText from "../components/portableText";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { buildImageObj, getSpotlightUrl, mapEdgesToNodes } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      _rawLogo
    }
    spotlights: allSanitySpotlight(sort: { order: DESC, fields: _createdAt }) {
      edges {
        node {
          id
          isReady
          network {
            id
            title
            _rawLogo
            slug {
              current
            }
            brandColors
          }
          intro {
            _rawSimpleWhatItIs
          }
        }
      }
    }
    posts: allSanityPost(sort: { order: DESC, fields: publishedAt }, limit: 4) {
      nodes {
        id
        _rawMainImage
        _rawExcerpt
        _rawBody
        slug {
          current
        }
        title
        publishedAt(formatString: "DD MMM YY")
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const spotlightNodes = mapEdgesToNodes((data || {}).spotlights);

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout _rawLogo={site._rawLogo}>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Jumbotron fluid>
        <Container className="text-center text-sm-left">
          <h1 className="h2 mb-1">
            <em>See</em> blockchain networks
            <br />
            like never before
          </h1>
          <div className="lead mb-2">Tools, education and insights for crypto explorers</div>
          <Button
            className="text-decoration-none"
            variant="outline-dark"
            href="https://foundation.us4.list-manage.com/subscribe?u=caae9342f52a16ea36727f89c&id=413d37b977"
          >
            Sign up
          </Button>
        </Container>
      </Jumbotron>
      <Jumbotron fluid>
        <Container>
          <div className="overline mb-3 text-md-center">Rebase is</div>
          <Row>
            {/* <Col md="4" className="mb-3">
              <h5 className="mb-0">Rebase See</h5>
              <div className="mb-1">
                Visualize live blockchain data with a powerful, easy-to-use, no-code tool
              </div>
              <a href="#see">More info ↓</a>
            </Col> */}
            <Col md="5" className="mb-3 text-right">
              <h5 className="mb-0">Rebase Spotlight</h5>
              <div className="mb-1">The new standard in cryptonetwork explainers</div>
              <a href="#spotlight">More info ↓</a>
            </Col>
            <Col md="2" className="mb-3 text-md-center">
              <h5 className="mb-0">&</h5>
            </Col>
            <Col md="5" className="mb-3">
              <h5 className="mb-0">Rebase Research</h5>
              <div className="mb-1">
                Delve into the most interesting investment questions in crypto
              </div>
              <a href="#research">More info ↓</a>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Jumbotron id="spotlight">
        <Container>
          <h4 className="mb-2">Rebase Spotlight</h4>
          <div className="mb-3" style={{ maxWidth: "60ch" }}>
            The new standard for understanding and getting involved in top cryptonetworks. Simple
            introductions, deep dives and whitepaper walkthroughs. Click once to express your
            interest in a network role.
          </div>

          <h6 className="mb-2 overline">Current Spotlights</h6>
          <Row>
            {spotlightNodes.map(node => (
              <Col md="6" className="mb-3">
                <Card>
                  <Card.Body className="pt-0">
                    <a className="text-decoration-none" href={getSpotlightUrl(node.network.slug)}>
                      {/* <pre>{JSON.stringify(node.network, undefined, 2)}</pre> */}
                      <div>
                        <Row className="d-flex align-items-center">
                          <Col>
                            {node.network._rawLogo && node.network._rawLogo.asset && (
                              <img
                                src={imageUrlFor(buildImageObj(node.network._rawLogo))
                                  .auto("format")
                                  .url()}
                                className="d-block my-3"
                                style={{ width: 150 }}
                              />
                            )}
                          </Col>
                          <Col>
                            <h6 className="mb-0 text-right">{node.network.title}</h6>
                          </Col>
                        </Row>
                        <BrandColorsHorizontalDivider brandColors={node.network.brandColors} />
                        {node.intro && node.intro._rawSimpleWhatItIs && (
                          <div>
                            <PortableText blocks={node.intro._rawSimpleWhatItIs} />
                          </div>
                        )}
                      </div>
                    </a>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Jumbotron>
      <Jumbotron id="research">
        <Container>
          <h4 className="mb-2">Rebase Research</h4>
          <div className="mb-3" style={{ maxWidth: "60ch" }}>
            An exploration of the most valuable current investment themes. Through interviews with
            top crypto minds, first looks and deep dive content.
          </div>

          <div className="mb-3">
            <Link to="/blog">Explore →</Link>
          </div>

          <h6 className="mb-2 overline">Recent posts</h6>
          <Row>
            {data.posts.nodes.map(node => (
              <Col xs="12" lg="6" className="mb-3">
                <Card>
                  <Link
                    className="text-decoration-none"
                    to={node.slug ? "/post/" + node.slug.current : ""}
                  >
                    <div>
                      <Row className="d-flex align-items-center" noGutters>
                        <Col className="mr-1">
                          {node._rawMainImage && node._rawMainImage.asset && (
                            <img
                              src={imageUrlFor(buildImageObj(node._rawMainImage))
                                .auto("format")
                                .width(600)
                                .height(300)
                                .url()}
                              className="d-block img-fluid rounded-left"
                              style={{ width: 300 }}
                            />
                          )}
                        </Col>
                        <Col className="ml-1">
                          <h6 className="mb-0">{node.title}</h6>
                        </Col>
                      </Row>
                    </div>
                  </Link>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Jumbotron>
    </Layout>
  );
};

export default IndexPage;
